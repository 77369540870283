$cl : ".app-page-ticket-detail";

#{$cl}{
    padding: 10px 30px 30px 30px;
    border-top: 1px solid #eaeaea;
    &__title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1{
            margin:0;
            font-size: 20px;
            color: #262626;
        }
        .MuiButtonBase-root{
            color: $primary-color;
            margin-right: 6px;
        }
        div{
            display: flex;
            align-items: center;
        }
    }
    &__user_logo{
       height: 50px;
       width: 80px;
       margin-right: 10px;
       object-fit: contain;
    }
    &__edit_button{
        display: flex;
        font-size: 18px !important;
        gap: 5px;
        // background-color: rgba(255, 122, 0, 0.8) !important;
        // color: white !important;
    }
    &__body{
        margin-top: 10px;
    }
    &__ticketcard{
        padding: 20px;
        background-color: #ffffff;
        border-radius: 20px;
        &__header{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            align-items: flex-start;
        }
        &__subject{
            display: flex;
            flex-direction: column;
            h3{
                font-size: 18px;
                margin:0;
                span{
                    color: #838383;
                    font-weight: 500;
                }
            }
        }
        &__actions{
            margin-left: 15px;
            min-width: 160px;
            .MuiInputBase-root.proiority-select .MuiSelect-select {
                padding: 8px 25px 8px 10px !important;
                font-size: 15px;
                font-weight: 600;
                box-shadow: 0 0 4px #eaeaea;
            }
        }
        &__body{
            margin-top: 10px;
        }
    }
    &__ticketinfo{
        display: flex;
        flex-direction: column;
        h6{
            font-weight: 500;
            font-size: 15px;
            margin:0;
            color: #6c757d;
        }
        p{
            font-weight: 600;
            color: #262626;
            margin:5px 0 0 0;
            font-size: 15px;
        }
    }
    &__discussion{
        padding: 20px;
        background-color: #ffffff;
        border-radius: 20px;
        margin-top: 30px;
        &--title{
            font-size: 18px;
            margin: 0;
        }
        &__list{
            display: flex;
            flex-direction: column;
            list-style: none;
            padding:10px 0 0 0;
            margin:0;
            &item{
                padding:12px 0;
                &:not(:last-child){
                    border-bottom: 1px solid #f3f3f3;
                }
            }
        }
        
    }
    &__customerdetailscard{
        padding: 20px;
        background-color: #ffffff;
        border-radius: 20px;
        position: sticky;
        top:10px;
        &--title{
            font-size: 18px;
            margin: 0;
        }
        &--list{
            display: flex;
            flex-direction: column;
            list-style: none;
            margin:10px 0 0 0;
            padding:0;
            &item{
                display: flex;
                align-items: center;
                margin:10px 0;
                .MuiSvgIcon-root {
                    background-color: #f6f6f6;
                    width: 35px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    border-radius: 50%;
                    padding: 7px;
                    color: #6b6b6b;
                    margin-right: 15px;
                }
                h6{
                    font-weight: 500;
                    font-size: 15px;
                    margin: 0;
                    color: #6c757d;
                }
                p{
                    font-weight: 600;
                    color: #262626;
                    margin: 3px 0 0 0;
                    font-size: 15px;
                }
            }
        }
    }
}
.status-chip{
    background-color: #f6f6f6;
    padding: 6px 20px;
    border-radius: 20px;
    color: #262626;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    width: fit-content;
    &.--low{
        color: #ffffff;
        background-color: rgb(102, 88, 221);
    }
    &.--medium{
        color: #ffffff;
        background-color: rgb(247, 184, 75);
    }
    &.--high{
        color: #ffffff;
        background-color: rgb(241, 85, 108);
    }
}
.attachment-list{
    padding:0;
    margin:0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    &-item{
        display: flex;
        padding:10px 5px 10px 10px;
        background-color: #f8f8f8;
        border: 1px solid #eaeaea;
        border-radius: 4px;
        align-items: center;
        margin:8px 8px 0 0;
        &--image{
            color: #838383;
            margin-right: 4px;
        }
        &--title{
            font-size: 14px;
        }
        .MuiButtonBase-root{
            margin-left: 4px;
            color: #ababab;
            &:hover{
                color: $primary-color;
            }
        }
    }
}
.discussion-profile{
    display: flex;
    width: 100%;
    &--pic{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
    }
    &--info{
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        width:calc(100% - 30px)
    }
    &--header{
        display: flex;
        justify-content: space-between;
        h6{
            margin:0;
            font-size: 14px;
            color: #262626;
        }
        span{
            font-size: 12px;
        }
    }
    p{
        font-size: 14px;
        color: #838383;
        margin: 4px 0 0 0;
    }
    &--reply{
        .MuiButtonBase-root{
            color: #98a6ad;
            align-items: center;
            .MuiSvgIcon-root{
                font-size: 16px;
                margin-right: 2px;
            }
        }
    }
}

.discussion-comment{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    textarea{
        border: 1px solid #eaeaea;
        border-radius: 4px;
        padding: 8px 10px;
        outline: 0;
        &:hover,
        &:focus{
            border-color: $primary-color;
        }
    }
    .MuiButtonBase-root{
        margin-top: 12px;
        width: fit-content;
        align-self: flex-end;
        .MuiSvgIcon-root{
            font-size: 16px;
            margin-right: 5px;
        }
    }
}
